<template>
  <div class="text-center" id="modal-survey-popup">
    <div
      class="
        evaluate-star
        d-flex
        justify-content-center
        align-items-center
        text-center
      "
    >
      <div class="evaluate-star__evaluation">
        <img src="../assets/image/star.svg" alt="" />
      </div>
      <div class="evaluate-star__opponentScore">
        <img src="../assets/image/star.svg" alt="" />
      </div>
      <div class="evaluate-star__prego-app">
        <img src="../assets/image/star.svg" alt="" />
      </div>
    </div>
    <div class="thanks f-w3 text-center">
      <p class="mb-0">ありがとうございます。</p>
      <p>今後のアプリケーションの向上に役立てます。</p>
    </div>
    <button class="returnHome f-w3" @click="returnHome">
      ホーム画面に戻る
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SurveyPopup",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user_info: "auth/user"
    })
  },
  methods: {
    returnHome() {
      this.$root.$refs.loading.start();
      if (this.user_info.sex == 1) {
        // this.$router.push({ name: "Home" });
        location.replace("/");
      } else {
        location.replace("/cast");
      }
      this.$root.$refs.loading.finish();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_fontFamily";

#modal-survey-popup {
  .modal-dialog {
    margin: 0;
    .modal-body {
      padding-top: 0;
    }
    .modal-header {
      border-bottom: unset;
    }
    .modal-content {
      border: none;
      border-radius: 0;
    }
  }
  .evaluate-star {
    &__evaluation {
      img {
        width: 61.57px;
        height: 58.71px;
        margin: 0 5.5px;
      }
    }
    &__opponentScore {
      img {
        width: 81px;
        margin: 0 5.5px;
        height: 77.26px;
      }
    }
    &__prego-app {
      img {
        width: 61.57px;
        margin: 0 5.5px;
        height: 58.71px;
      }
    }
  }
  .thanks {
    color: $black;
    font-size: 16px;
    margin: 25.74px 0 35px 0;
  }
  .returnHome {
    border-radius: 5px;
    width: 100%;
    height: 45px;
    background-color: #464d77;
    color: $white;
    font-size: 16px;
    outline: none;
    border: none;
  }
}
@media screen and (max-width: 3754px) {
  .returnHome {
    max-width: 350px !important;
  }
}
</style>
