<template>
  <div class="grid-container">
    <main class="grid-item main">
      <div class="items d-flex" :id="id_item">
        <div
          class="item item0"
          v-for="(score, index) in dataSlide"
          :key="index"
          :id="id_item + index"
          :class="index == 102 ? 'max-score' : ''"
        >
          {{ score }}
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    dataSlide: {
      type: Array,
      default: null
    },
    defaultId: {
      type: Number,
      default: 5
    },
    id_item: {
      type: String,
      default: null
    }
  },
  name: "sliderScore",
  data() {
    return {
      dataScore: []
    };
  },
  created() {
    this.dataScore.push("");
    for (let j = 60; j <= 300; j++) {
      this.dataScore.push(j.toString());
    }
    this.dataScore.push("");
  },
  mounted: function() {
    const vm = this;
    const slider = document.getElementById(this.id_item);
    let isDown = false;
    let startX;
    let scrollLeft;
    let id = this.defaultId;
    slider.scrollLeft = id * 70 - 70;
    document.getElementById(this.id_item + id).classList.add("active");
    slider.addEventListener("touchstart", swipe);
    slider.addEventListener("touchend", swipe);
    slider.addEventListener("touchmove", swipe);

    function swipe(event) {
      if (event.type == "touchstart") {
        if (id > 0)
          document.getElementById(vm.id_item + id).classList.remove("active");
        isDown = true;
        slider.classList.add("active");
        startX = event.targetTouches[0].clientX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      }
      if (event.type == "touchmove") {
        if (!isDown) return;
        event.preventDefault();
        const x = event.targetTouches[0].clientX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      }
      if (event.type == "touchend") {
        slider.classList.remove("active");
        id = Math.floor(slider.scrollLeft / 70) + 1;
        if (slider.scrollLeft < 70) {
          id = 1;
        }
        slider.scrollLeft = id * 70 - 70;
        document.getElementById(vm.id_item + id).classList.add("active");
        vm.$emit("score", id);
      }
    }
    slider.addEventListener("mousedown", e => {
      if (id > 0)
        document.getElementById(this.id_item + id).classList.remove("active");
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
      id = Math.floor(slider.scrollLeft / 70) + 1;
      if (slider.scrollLeft < 70) {
        id = 1;
      }
      slider.scrollLeft = id * 70 - 70;
      document.getElementById(this.id_item + id).classList.add("active");
      vm.$emit("score", id);
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
      id = Math.floor(slider.scrollLeft / 70) + 1;
      if (slider.scrollLeft < 70) {
        id = 1;
      }
      slider.scrollLeft = id * 70 - 70;
      document.getElementById(this.id_item + id).classList.add("active");
      vm.$emit("score", id);
    });
    slider.addEventListener("mousemove", e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }
};
</script>

<style lang="scss" scoped>
.grid-container {
  font-family: "ITC Bookman, Light Italic";
}

//ENABLE CSS GRID FOR LIFT OFF  🚀
@supports (display: grid) {
  .title {
    grid-area: title;
  }

  .main {
    width: 220px;
    grid-area: main;
    padding: 0;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .items {
    position: relative;
    width: 200px;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    &#data-score {
      width: 215px;
    }
  }

  .items.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    margin: 0 10px;
    font-size: 20px;
    line-height: 36px;
    color: #8f8f8f;
    &.active {
      font-size: 30px;
      line-height: 36px;
      color: #cb4f3d;
    }
    &.max-score {
      margin-left: 20px;
      font-size: 17px;
      &.active {
        font-size: 25px;
        line-height: 36px;
        color: #cb4f3d;
      }
    }
    @media screen and (max-width: 1080px) {
      min-width: 50px;
    }
  }
  @media screen and (min-width: 1080px) {
    .item {
      font-size: 23px;
      line-height: 40px;
      &.active {
        font-size: 34px;
        line-height: 40px;
      }
    }
  }
}
</style>
